import { nextTick } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import _laxios from '../../../../store/_laxios';

const IMPORTANT_TRIGGER_IDS = [2021, 1572, 2395, 2786];

export default {
  name: 'OneNotification',
  data() {
    return {
      open: false,
      pressClose: false,
      checked: false,
      observer: null,
    };
  },
  props: ['data', 'ind', 'disabledRemoveBtn', 'checkedAll', 'pageType'],
  mounted() {
    this.open = this.hasImportantTriggerId;
    this.observeNotification();
  },
  methods: {
    // @todo вынести в глобал
    getFormatedData(date) {
      this.m.relativeTimeThreshold('m', 59);
      switch (true) {
        case Boolean(Date.now() - Number(date.format('x')) <= 12 * 3600 * 1000):
          return this.m(date).fromNow();
          break;
        case Boolean(Date.now() - Number(date.format('x')) <= 48 * 3600 * 1000):
          return this.m(date).calendar();
          break;
        case Boolean(Number(date.format('YYYY')) === new Date().getFullYear()):
          return this.m(date).format('DD MMM').replace('.', '');
          break;
        default:
          return this.m(date).format('DD MMM YYYY').replace('.', '');
      }
    },
    /**
     * Метод для закритя/відкриття сповіщення
     * @param id - унікальний ідентифікатор сповіщення
     * @param isViewed - чи переглянуте сповіщення
     * @param notificationType - тип сповіщення
     */
    toggleNotification(id, isViewed, notificationType, switchVisibility = true) {
      this.reloadMainPage();
      if (!isViewed) {
        switch (notificationType) {
          case 1:
            this.decrementEmergencyCnt();
            this.incrementEmergencyViewedCnt();
            this.data.is_viewed = true;
            break;
          case 2:
            this.decrementImportantCnt();
            this.incrementImportantViewedCnt();
            this.data.is_viewed = true;
            break;
          case 3:
            this.decrementInformingCnt();
            this.incrementInformingViewedCnt();
            this.data.is_viewed = true;
            break;
        }

        const data = {
          values: {
            is_viewed: true,
          },
          filter: {
            nc_notification_id: [id],
          },
        };

        // відправляємо запит на notification про перегляд
        _laxios.notifications_PATCH
          .request({ data }).catch((e) => {
            console.error('notifications_PATCH', e.message);
          });
      }

      if (switchVisibility) this.open = !this.open;
    },
    /**
     * Метод для видалення повідомлення
     * @param id - унікальний ідентифікатор сповіщення
     * @param isViewed - чи переглянуте сповіщення
     * @param notificationType - тип сповіщення
     * @param disabledRemoveBtn - чи можна видаляти сповіщення заборона діє 500 мс після видалення попереднього
     */
    removeNotification(id, isViewed, notificationType, disabledRemoveBtn) {
      if (!disabledRemoveBtn) {
        this.$emit('onPreloaderOn');
        this.pressClose = true;

        // віднімаємо лічильники
        switch (notificationType) {
          case 1:
            isViewed ? this.decrementEmergencyViewedCnt() : this.decrementEmergencyCnt();
            this.data.is_deleted = true;
            break;
          case 2:
            isViewed ? this.decrementImportantViewedCnt() : this.decrementImportantCnt();
            this.data.is_deleted = true;
            break;
          case 3:
            isViewed ? this.decrementInformingViewedCnt() : this.decrementInformingCnt();
            this.data.is_deleted = true;
            break;
        }

        // при видаленні робимо повідомлення прочитаним також
        const data = {
          values: {
            is_deleted: true,
            is_viewed: true,
          },
          filter: {
            nc_notification_id: [id],
          },
        };

        // data2 - запит на відновлення всіх видалених сповіщень в юзера, викр. для тесту
        // let data2 = {
        //   values: {
        //     is_deleted: false,
        //     is_viewed: false
        //   },
        //   filter: {
        //     is_deleted: true
        //   }
        // };

        // відправляємо запит на notification про видалення
        _laxios.notifications_PATCH
          .request({ data })
          .then((payload) => {
            this.$emit('onRemoveNotification', id);
          }).catch((e) => {
            console.error('notifications_PATCH', e.message);
          });
      }
    },
    /**
     * Відправка запиту про перегляд важливого notification
     * після потрапляння в зону видимості
     */
    observeNotification() {
      const elementToObserve = this.$refs[`${this.ind}-ref`];
      if (!elementToObserve) return;

      // Callback function when the observed element enters or exits the viewport
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.data.is_viewed && this.hasImportantTriggerId) {
            setTimeout(() => {
              this.toggleNotification(
                this.data.nc_notification_id,
                false,
                this.data.notification_type,
                false,
              );
            }, 5000);
          }
        });
      };

      // eslint-disable-next-line no-undef
      this.observer = new IntersectionObserver(handleIntersection);
      this.observer.observe(elementToObserve);
    },
    /**
     * Мутації для лічильників
     */
    ...mapMutations({
      decrementEmergencyCnt: 'cabinet/leftBar/decrementEmergencyCnt',
      decrementImportantCnt: 'cabinet/leftBar/decrementImportantCnt',
      decrementInformingCnt: 'cabinet/leftBar/decrementInformingCnt',
      decrementEmergencyViewedCnt: 'cabinet/notifications/decrementEmergencyViewedCnt',
      decrementInformingViewedCnt: 'cabinet/notifications/decrementInformingViewedCnt',
      decrementImportantViewedCnt: 'cabinet/notifications/decrementImportantViewedCnt',
      incrementEmergencyViewedCnt: 'cabinet/notifications/incrementEmergencyViewedCnt',
      incrementInformingViewedCnt: 'cabinet/notifications/incrementInformingViewedCnt',
      incrementImportantViewedCnt: 'cabinet/notifications/incrementImportantViewedCnt',
      reloadMainPage: 'cabinet/notifications/reloadMainPage',
    }),
    /**
     * Метод який обнуляє всі дії над компонентою
     */
    resetComp() {
      if (this.observer) this.observer.disconnect();

      this.open = this.hasImportantTriggerId;
      this.pressClose = false;
      this.checked = false;

      nextTick(() => this.observeNotification());
    },
  },
  watch: {
    checked(check) {
      this.$emit('onChangeCheckbox', this.data.nc_notification_id, check);
    },
    /**
     * reset коли відбувається переход на іншу сторінку (тип, пагінація)
     */
    ind() {
      this.resetComp();
    },
    /**
     * reset при видалені будь-якого сповіщення
     */
    pressClose() {
      this.resetComp();
    },
    /**
     * перевіряємо чи з батьківсьокої компоненти не прийшов пропс - "Виділити все"
     * якщо прийшов то змінюємо всі чекбокси на значення пропса "Виділити все"
     * @param newValue
     */
    checkedAll(newValue) {
      this.checked = newValue;
    },
  },
  computed: {
    ...mapGetters({
      langId: 'lang/id',
    }),
    hasImportantTriggerId() {
      return IMPORTANT_TRIGGER_IDS.includes(this.data.trigger_id);
    },
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
