import {mapGetters, mapMutations} from 'vuex';
const {get, set} = require('../../../helpers/cookie');
import _laxios from '../../../store/_laxios';
import OneNotification from './OneNotification/index.vue';
import Pagination from './../../Common/Pagination/index.vue';
const EXPIRES = 2592000;

Array.prototype.remove = function () {
	let what; let a = arguments; let L = a.length; let ax;
	while (L && this.length) {
		what = a[--L];
		while ((ax = this.indexOf(what)) !== -1) {
			this.splice(ax, 1);
		}
	}
	return this;
};

export default {
	name: 'Notifications',
	data: function () {
		return {
			type: '',
			notifications: {},
			page: 1,
			limit: _TARGET_ === 'client' ? (Number(get('cabinet_ipp_notification')) || 10) : 10,
			currentPage: [],
			emptyPage: false,
			disabledRemoveBtn: false,
			checkedAll: false,
			massActionCheckedObj: [],
			massActionSelect: 0,
			selectValid: true,
			selectDisabled: true,
			preloader: false,
			isArchive: false
		};
	},
	components: {
		'one-notification': OneNotification,
		'pagination': Pagination
	},
	init({store, context}) {
		let cookies = {};
		if (context) {
			cookies = context.request.headers.cookie;
		}
		return store.dispatch('cabinet/notifications/counters', cookies);

	},
	created() {
		this.type = this.$route.meta.type;
	},
	watch: {
		$route(to) {
			this.emptyPage = false;
			this.page = 1;
			this.type = to.meta.type;
			this.resetToDefault();
		}
	},
	mounted() {
		this.setPage(this.page, this.$route.meta.typeId, this.limit);
	},
	methods: {
		resetToDefault() {
			this.massActionCheckedObj = [];
			this.checkedAll = false;
			// коли видаляють все з останньої сторніки, зміщуємось на сторінку назад
			if (this.pages < this.page && this.page > 1) {
				this.page--;
			}
			this.setPage(this.page, this.$route.meta.typeId, this.limit);
		},
		onPreloaderOn() {
			this.preloader = true;
		},
		/**
     * Метод який трігириться в компоненті пагінації
     * на зміну кількості сповіщень в видачі
     * @param limit
     */
		onChangeLimit(limit) {
			this.page = 1;
			this.limit = limit;
			set('cabinet_ipp_notification', limit, { expires: EXPIRES });
			this.resetToDefault();
		},
		/**
     * Метод який трігириться в компоненті пагінації
     * перехід по сторінкам
     * @param page
     */
		onChangePage(page) {
			this.page = page;
			this.resetToDefault();
		},
		/**
     * Метод який трігириться в дочірній компоненті OneNotification
     * Коли сповіщення видаляється кнопкою хрестик
     * @param id
     */
		onRemoveNotification(id) {
			this.incrementDeletedCnt();
			this.reloadMainPage();
			this.disabledRemoveBtn = true;
			this.notifications = {};
			this.resetToDefault();
			setTimeout(function () {
				this.disabledRemoveBtn = false;
				this.preloader = false;
			}.bind(this), 500);
		},
		/**
     * Масові дії
     * Метод який трігириться в дочірній компоненті OneNotification
     * Додає в обєкт відмічені сповіщення (або видаляє)
     * @param id - унікальний ідентифікатор
     * @param check - відмітка
     */
		onChangeCheckbox(id, check) {
			if (check) {
				this.massActionCheckedObj.push(id);
			} else {
				this.massActionCheckedObj.remove(id);
			}
			this.selectDisabled = !Object.keys(this.massActionCheckedObj).length;
			this.massActionSelect = !Object.keys(this.massActionCheckedObj).length ? 0 : this.massActionSelect;
		},
		/**
     * Метод для масових дій
     * Кнопка "застосувати"
     */
		massActionSubmit() {
			if (Number(this.massActionSelect)) {
				if (!this.selectDisabled) {
					switch (Number(this.massActionSelect)) {
						case 1:
							this.massActionViewed();
							break;
						case 2:
							this.massActionRemove();
							break;
					}
				}
			} else {
				this.selectValid = false;
				setTimeout(function () {
					this.selectValid = true;
				}.bind(this), 1500);
			}
		},
		/**
     * Метод для масових дій
     * "удалить в архив"
     */
		massActionRemove() {
			this.preloader = true;
			this.reloadMainPage();
			// при видаленні робимо повідомлення прочитаним також
			let data = {
				values: {
					is_viewed: true,
					is_deleted: true
				},
				filter: {
					nc_notification_id: []
				}
			};

			this.currentPage.forEach((oneItem) => {
				if (this.massActionCheckedObj.includes(oneItem.nc_notification_id)) {
					data.filter.nc_notification_id.push(oneItem.nc_notification_id);
					this.incrementDeletedCnt();
					oneItem.is_deleted = true;

					// віднімаємо лічильники
					switch (oneItem.notification_type) {
						case 1:
							oneItem.is_viewed ? this.decrementEmergencyViewedCnt() : this.decrementEmergencyNewCnt();
							break;
						case 2:
							oneItem.is_viewed ? this.decrementImportantViewedCnt() : this.decrementImportantNewCnt();
							break;
						case 3:
							oneItem.is_viewed ? this.decrementInformingViewedCnt() : this.decrementInformingNewCnt();
							break;
					}
				}
			});

			// відправляємо запит на notification про видалення
			_laxios.notifications_PATCH
				.request({data}).then(function () {

					this.notifications = {};
					this.resetToDefault();
					this.preloader = false;

				}.bind(this)).catch((e) => {
				console.error('notifications_PATCH', e.message);
			});

		},
		/**
     * Метод для масових дій
     * "отметить как прочитаное"
     */
		massActionViewed() {
			let data = {
				values: {
					is_viewed: true
				},
				filter: {
					nc_notification_id: []
				}
			};

			this.currentPage.forEach((oneItem) => {
				if (this.massActionCheckedObj.includes(oneItem.nc_notification_id) && !oneItem.is_viewed) {
					data.filter.nc_notification_id.push(oneItem.nc_notification_id);
					oneItem.is_viewed = true;

					switch (oneItem.notification_type) {
						case 1:
							this.decrementEmergencyNewCnt();
							this.incrementEmergencyViewedCnt();
							break;
						case 2:
							this.decrementImportantNewCnt();
							this.incrementImportantViewedCnt();
							break;
						case 3:
							this.decrementInformingNewCnt();
							this.incrementInformingViewedCnt();
							break;
					}

				}
			});

			// відправляємо запит на notification про перегляд
			_laxios.notifications_PATCH
				.request({data}).then(function () {
					this.checkedAll = false;
					this.setPage(this.page, this.$route.meta.typeId, this.limit);
				}.bind(this)).catch((e) => {
				console.error('notifications_PATCH', e.message);
			});

		},
		activeCount() {
			let emergencyNew = this.notificationCounters.NotificationsEmergency && this.notificationCounters.NotificationsEmergency.count || 0;
			let importantNew = this.notificationCounters.NotificationsImportant && this.notificationCounters.NotificationsImportant.count || 0;
			let informingNew = this.notificationCounters.NotificationsInforming && this.notificationCounters.NotificationsInforming.count || 0;
			let emergencyViewed = this.counters.emergencyViewed || 0;
			let importantViewed = this.counters.importantViewed || 0;
			let informingViewed = this.counters.informingViewed || 0;

			switch (this.$route.meta.typeId) {
				case 0:
					return this.limit < (emergencyNew + importantNew + informingNew + emergencyViewed + importantViewed + informingViewed) ? this.limit : (emergencyNew + importantNew + informingNew + emergencyViewed + importantViewed + informingViewed);
					break;
				case 1:
					return this.limit < (emergencyNew + emergencyViewed) ? this.limit : (emergencyNew + emergencyViewed);
					break;
				case 2:
					return this.limit < (importantNew + importantViewed) ? this.limit : (importantNew + importantViewed);
					break;
				case 3:
					return this.limit < (informingNew + informingViewed) ? this.limit : (informingNew + informingViewed);
					break;
			}
		},
		/**
     * Сетапить сторінку сповіщень відносно отриманих параметрів
     * @param page - порядковий номер сторінки, яку потрібно відмалювати
     * @param type - тип нотифікації
     * @param limit - ліміт
     * @param trigger_id
     */
		setPage(page, type, limit = 10, trigger_id) {
			let key = 'page' + page + 'type' + type + (trigger_id ? ('trigger' + trigger_id) : '') + 'limit' + limit;
			if (!this.notifications[key] || !this.notifications[key].length) {
				let data = {
					skip: page * limit - limit,
					limit: limit,
					sort: {
						add_date: -1
					},
					filter: {
						is_deleted: this.$route.meta.typeId === 4,
						trigger_id: trigger_id ? [trigger_id] : undefined,
						notification_type: type && type < 4 ? [type] : undefined
					}
				};

				_laxios.notificationsSearch
					.request({data})
					.then(payload => {
						this.notifications[key] = payload;
						this.currentPage = payload;
						if (!payload.length) {
							this.emptyPage = true;
						}
					}).catch((e) => {
					console.error('notificationsSearch', e.message);
				});
			} else {
				this.currentPage = this.notifications[key];
			}
		},
		/**
     * Мутації для лічильників
     */
		...mapMutations({
			decrementEmergencyNewCnt: 'cabinet/leftBar/decrementEmergencyCnt',
			decrementImportantNewCnt: 'cabinet/leftBar/decrementImportantCnt',
			decrementInformingNewCnt: 'cabinet/leftBar/decrementInformingCnt',
			decrementEmergencyViewedCnt: 'cabinet/notifications/decrementEmergencyViewedCnt',
			decrementInformingViewedCnt: 'cabinet/notifications/decrementInformingViewedCnt',
			decrementImportantViewedCnt: 'cabinet/notifications/decrementImportantViewedCnt',
			incrementEmergencyViewedCnt: 'cabinet/notifications/incrementEmergencyViewedCnt',
			incrementInformingViewedCnt: 'cabinet/notifications/incrementInformingViewedCnt',
			incrementImportantViewedCnt: 'cabinet/notifications/incrementImportantViewedCnt',
			incrementDeletedCnt: 'cabinet/notifications/incrementDeletedCnt',
			reloadMainPage: 'cabinet/notifications/reloadMainPage'
		})
	},
	computed: {
	  ...mapGetters({
		  counters: 'cabinet/notifications/counters',
		  notificationCounters: 'cabinet/leftBar/notificationCounters',
		  langId: 'lang/id'
	  }),
	  pages() {
		if (this.counters && this.notificationCounters && this.$route.meta.typeId !== 4) {
		  let emergencyNew = this._result(this.notificationCounters, 'NotificationsEmergency.count') || 0;
		  let importantNew = this._result(this.notificationCounters, 'NotificationsImportant.count') || 0;
		  let informingNew = this._result(this.notificationCounters, 'NotificationsInforming.count') || 0;
		  let emergencyViewed = this.counters.emergencyViewed || 0;
		  let importantViewed = this.counters.importantViewed || 0;
		  let informingViewed = this.counters.informingViewed || 0;

		  switch (this.$route.meta.typeId) {
			case 0:
			  return Math.ceil((emergencyNew + importantNew + informingNew + emergencyViewed + importantViewed + informingViewed) / this.limit);
			case 1:
			  return Math.ceil((emergencyNew + emergencyViewed) / this.limit);
			case 2:
			  return Math.ceil((importantNew + importantViewed) / this.limit);
			case 3:
			  return Math.ceil((informingNew + informingViewed) / this.limit);
		  }
		} else if (this.counters && this.notificationCounters && this.$route.meta.typeId === 4) {
		  return Math.ceil((this.counters.deleted) / this.limit);
		}
	  }
	},
	i18n: {
		messages: {
			uk: {
				'уведомлений': 'сповіщень',
				'Сейчас у Вас нет': 'Зараз у Вас немає',
				'экстренных': 'екстренних',
				'важных': 'важливих',
				'информирующих': 'інформуючих',
				'Архив': 'Архів',
				'Уведомления': 'Сповіщення',
				'Все': 'Усі',
				'Экстренные': 'Екстрені',
				'Важные': 'Важливі',
				'Информирующие': 'Інформаційні',
				'Вернуться к активным уведомлениям': 'Повернутися до активних сповіщень',
				'Выделить все': 'Виділити все',
				'Выберите действие': 'Виберіть дію',
				'Отметить как прочитаное': 'Помітити як прочитане',
				'Удалить в архив': 'Видалити в архів',
				'Применить': 'Застосувати',
				'Налаштувати': 'Налаштувати',
			},
			ru: {
				'уведомлений': 'уведомлений',
				'Сейчас у Вас нет': 'Сейчас у Вас нет',
				'экстренных': 'экстренных',
				'важных': 'важных',
				'информирующих': 'информирующих',
				'Архив': 'Архив',
				'Уведомления': 'Уведомления',
				'Все': 'Все',
				'Экстренные': 'Экстренные',
				'Важные': 'Важные',
				'Информирующие': 'Информирующие',
				'Вернуться к активным уведомлениям': 'Вернуться к активным уведомлениям',
				'Выделить все': 'Выделить все',
				'Выберите действие': 'Выберите действие',
				'Отметить как прочитаное': 'Отметить как прочитаное',
				'Удалить в архив': 'Удалить в архив',
				'Применить': 'Применить',
				'Налаштувати': 'Настроить',
			}
		}
	}
};
